import { h, resolveComponent } from 'vue'
import store from '../store'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { name: "home"},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/app-product-data',
        name: 'App Product Data',
        meta: { name: "app_product"},
        component: () => import('@/views/pages/appProductData.vue'),
      },
      {
        path: 'products/',
        name: 'Products',
        meta: { name: "product"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/products/listing',
        children: [
          {
              path: '/products/listing',
              name: 'Product Listing',
              meta: { name: "product"},
              component: () => import('@/views/product/product_listing.vue')
          },
          {
            path: '/products/edit/:productUri',
            name: 'Edit Product',
            meta: { name: "product"},
            component: () => import('@/views/product/edit_product.vue')
          },
          {
            path: '/products/add',
            name: 'Add Product',
            meta: { name: "product"},
            component: () => import('@/views/product/add_product.vue')
          },
          {
            path: '/accessories/listing',
            name: 'Accessory Listing',
            meta: { name: "product"},
            component: () => import('@/views/product/accessory_listing.vue')
          },
          {
            path: '/accessories/edit/:productUri',
            name: 'Edit Accessory',
            meta: { name: "product"},
            component: () => import('@/views/product/edit_accessory.vue')
          },
          {
            path: '/accessories/add',
            name: 'Add Accessory',
            meta: { name: "product"},
            component: () => import('@/views/product/add_accessory.vue')
          },
          {
            path: '/products/registration',
            name: 'Product Registration',
            meta: { name: "product"},
            component: () => import('@/views/product/product_registration.vue')
          }
        ]
      },
      {
        path: 'curriculum/',
        name: 'Curriculum',
        meta: { name: "curriculum"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/curriculum/category/listing',
        children: [
          {
              path: '/curriculum/category/listing',
              name: 'Category Listing',
              meta: { name: "curriculum"},
              component: () => import('@/views/curriculum/category_listing.vue')
          },
          {
            path: '/curriculum/chapter/listing/:subCategoryUri',
            name: 'Chapter Listing',
            meta: { name: "curriculum"},
            component: () => import('@/views/curriculum/chapter_listing.vue')
          },
          {
            path: '/curriculum/chapter/:subCategoryUri/:chapterCode',
            name: 'Edit Chapter',
            meta: { name: "curriculum"},
            component: () => import('@/views/curriculum/edit_chapter.vue')
          },
          {
            path: '/curriculum/question/listing/:subCategoryUri',
            name: 'Question Listing',
            meta: { name: "curriculum"},
            component: () => import('@/views/curriculum/question_listing.vue')
          },
          {
            path: '/curriculum/quiz/listing',
            name: 'Quiz Listing',
            meta: { name: "curriculum"},
            component: () => import('@/views/curriculum/quiz_listing.vue')
          },
          {
            path: '/curriculum/generic/checkpoint/listing',
            name: 'Checkpoint Listing',
            meta: { name: "curriculum"},
            component: () => import('@/views/curriculum/generic_checkpoint_listing.vue')
          },
          {
            path: '/curriculum/localization/chapter/:chapterCode',
            name: 'Localization Chapter',
            meta: { name: "curriculum"},
            component: () => import('@/views/curriculum/chapter_localization.vue')
          },
          {
            path: '/curriculum/localize/chapter/:language/:chapterCode',
            name: 'Edit Localize Chapter',
            meta: { name: "curriculum"},
            component: () => import('@/views/curriculum/edit_localize_chapter.vue')
          },
        ]
      },
      {
        path: 'production/',
        name: 'Production',
        meta: { name: "production"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/production/bulk/qr',
        children: [
          {
              path: '/production/bulk/qr/:inventory_order_id?',
              name: 'Bulk Qr',
              meta: { name: "production"},
              component: () => import('@/views/production/bulk_qr.vue')
          },
          {
            path: '/production/brain/testing/:inventory_order_id?',
            name: 'Brain Testing',
            meta: { name: "production"},
            component: () => import('@/views/production/brain_testing.vue')
          },
          {
            path: '/production/return/brain/testing',
            name: 'Return Brain Testing',
            meta: { name: "production"},
            component: () => import('@/views/production/return_brain_testing.vue')
          },
          {
            path: '/production/sensor/testing',
            name: 'Sensor Testing',
            meta: { name: "production"},
            component: () => import('@/views/production/sensor_testing.vue')
          },
          {
            path: '/production/brain/qr/download/:inventory_order_id?',
            name: 'Brain QR Download',
            meta: { name: "production"},
            component: () => import('@/views/production/brain_print_download.vue')
          },
          {
            path: '/production/make/finished/stock/:epoch_key?',
            name: 'Make Finished Stock',
            meta: { name: "production"},
            component: () => import('@/views/production/make_finished_stock.vue')
          },
          {
            path: '/production/make/semi-finished/stock/:epoch_key?',
            name: 'Make Semi Finished Stock',
            meta: { name: "production"},
            component: () => import('@/views/production/make_semi_finished_stock.vue')
          },
          {
            path: '/production/finished/stock/listing',
            name: 'Finished Stock Listing',
            meta: { name: "production"},
            component: () => import('@/views/production/finished_stock_listing.vue')
          },
          {
            path: '/production/semi-finished/stock/listing',
            name: 'Semi Finished Stock Listing',
            meta: { name: "production"},
            component: () => import('@/views/production/semi_finished_stock_listing.vue')
          },
          {
            path: '/production/make/wip2',
            name: 'Make Wip2 Stock',
            meta: { name: "production"},
            component: () => import('@/views/production/make_wip2_stock.vue')
          },
          {
            path: '/production/wip2/stock/listing',
            name: 'Wip2 Stock Listing',
            meta: { name: "production"},
            component: () => import('@/views/production/wip2_stock_listing.vue')
          }
        ]
      },
      {
        path: 'raw-material/',
        name: 'Raw Material',
        meta: { name: "raw_material"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/raw-material/type',
        children: [
          {
              path: '/raw-material/type',
              name: 'Material Type',
              meta: { name: "raw_material"},
              component: () => import('@/views/raw_material/material_type.vue')
          },
          {
            path: '/raw-material/component',
            name: 'Material Type Components',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/material_components.vue')
          },
          {
            path: '/raw-material/component/type/:type_id/:type_name',
            name: 'Material Components By Type',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/material_components_by_type.vue')
          },
          {
            path: '/raw-material/update-material-quantity/:invoice_number?',
            name: 'Add Material Quantity',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/update_material_quantity.vue')
          },
          {
            path: '/raw-material/wip1-bom',
            name: 'Wip1 Bom',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/wip1_bom.vue')
          },
          {
            path: '/raw-material/wip2-bom',
            name: 'Wip2 Bom',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/wip2_bom.vue')
          },
          {
            path: '/raw-material/wip-bom/planning/:product_code',
            name: 'Wip2 Bom Planning',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/wip_bom_planning.vue')
          },
          {
            path: '/raw-material/semi-finished-bom',
            name: 'Semi Finished  Bom',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/semi_finished_bom.vue')
          },
          {
            path: '/raw-material/finished-bom',
            name: 'Finished  Bom',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/finished_bom.vue')
          },
          {
            path: '/raw-material/view/kit/bom/:product_code',
            name: 'Kit Full Bom',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/kit_bom.vue')
          },
          {
            path: '/raw-material/vendor',
            name: 'Vendor Listing',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/vendor_listing.vue')
          },
          {
            path: '/raw-material/vendor/invoices',
            name: 'Vendor Invoice Listing',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/vendor_invoice_listing.vue')
          },
          {
            path: '/raw-material/required/quantity',
            name: 'Required Quantity',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/check_required_quantity.vue')
          },
          {
            path: '/raw-material/check/component/details',
            name: 'Check Component Details',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/check_component_details.vue')
          },
          {
            path: '/raw-material/minimum/stock/view',
            name: 'Minimum Stock View',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/minimum_stock_view.vue')
          },
          {
            path: '/raw-material/critical/stock/view',
            name: 'Critical Stock View',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/critical_stock_view.vue')
          },
          {
            path: '/raw-material/material/weekly/view',
            name: 'Material Weekly View',
            meta: { name: "raw_material"},
            component: () => import('@/views/raw_material/material_weekly_view.vue')
          }
        ]
      },
      {
        path: '/lms',
        name: 'LMS',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/lms/courses',
        children: [
          {
            path: '/lms/courses/:microDegreeCode?',
            name: 'Courses',
            meta: { name: "lms"},
            component: () => import('@/views/lms/courses.vue'),
          },
          {
            path: '/lms/microdegree',
            name: 'Micro Degree',
            meta: { name: "lms"},
            component: () => import('@/views/lms/micro_degree.vue'),
          },
          // {
          //   path: '/lms/micro-degree/courses/:degreeCode',
          //   name: 'Micro Degree Courses',
          //   meta: { name: "lms"},
          //   component: () => import('@/views/lms/courses.vue'),
          // },
          {
            path: '/lms/class/:courseCode/:microDegreeCode?',
            name: 'Class',
            meta: { name: "lms"},
            component: () => import('@/views/lms/class.vue'),
          },
          {
            path: '/lms/class/details/:classCode',
            name: 'Class Details',
            meta: { name: "lms"},
            component: () => import('@/views/lms/class_details.vue'),
          },
          {
            path: '/lms/class/access/:classCode/:microDegreeCode?',
            name: 'Enroll Students',
            meta: { name: "lms"},
            component: () => import('@/views/lms/class_access.vue'),
          },
          {
            path: '/lms/class/batch/:classCode/:microDegreeCode?',
            name: 'Class Batch',
            meta: { name: "lms"},
            component: () => import('@/views/lms/class_batch.vue'),
          },
          {
            path: '/lms/batch/registration/:batchCode/:microDegreeCode?',
            name: 'Batch Registration',
            meta: { name: "lms"},
            component: () => import('@/views/lms/batch_access.vue'),
          },
          {
            path: '/lms/class/session/:batchCode',
            name: 'Session Scheduling',
            meta: { name: "lms"},
            component: () => import('@/views/lms/session_schedule.vue'),
          },
          {
            path: '/lms/class/session/attendance/:batchCode/:sessionId',
            name: 'Session Attendance',
            meta: { name: "lms"},
            component: () => import('@/views/lms/session_attendance.vue'),
          },
          {
            path: '/lms/class/club/:classCode',
            name: 'B2B2C Listing',
            meta: { name: "lms"},
            component: () => import('@/views/lms/club_data.vue'),
          },
          {
            path: '/lms/report/card/:batchCode/:accessCode',
            name: 'Report Card',
            meta: { name: "lms"},
            component: () => import('@/views/lms/report_card.vue'),
          },
          {
            path: '/lms/feedback',
            name: 'Session Ending Feedback (Student)',
            meta: { name: "lms"},
            component: () => import('@/views/lms/feedback_details.vue'),
          },
          {
            path: '/lms/session/completion/feedback',
            name: 'Session Ending Feedback (TA)',
            meta: { name: "lms"},
            component: () => import('@/views/lms/session_completion_feedback_details.vue'),
          },
          {
            path: '/lms/user/submission',
            name: 'Users Submission Detail',
            meta: { name: "lms"},
            component: () => import('@/views/lms/user_submission_detail.vue'),
          }
        ]
      },
      {
        path: 'lms-analytics/',
        name: 'Lms Analytics',
        meta: { name: 'lms_analytics'},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/lms-analytics/session/schedule',
        children: [
          {
            path: '/lms-analytics/session/schedule',
            name: 'Session Schedule',
            meta: { name: 'lms_analytics'},
            component: () => import('@/views/lms_analytics/session_schedule.vue')
          },
          {
            path: '/lms-analytics/session/attendance',
            name: 'Session Analytics Attendance',
            meta: { name: 'lms_analytics'},
            component: () => import('@/views/lms_analytics/session_attendance.vue')
          },
          {
            path: '/lms-analytics/session/reschedule',
            name: 'Session Reschedule',
            meta: { name: 'lms_analytics'},
            component: () => import('@/views/lms_analytics/session_reschedule.vue')
          }
        ]
      },
      {
        path: 'projects/',
        name: 'Projects',
        meta: { name: "projects"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/projects/pending',
        children: [
          {
            path: '/projects/analytics',
            name: 'Analytics',
            meta: { name: "projects"},
            component: () => import('@/views/projects/projectStats.vue')
        },
          {
              path: '/projects/pending',
              name: 'Projects Pending',
              meta: { name: "projects"},
              component: () => import('@/views/projects/pendingProjects.vue')
          },
          {
            path: '/projects/approved',
            name: 'Projects Approved',
            meta: { name: "projects"},
            component: () => import('@/views/projects/approvedProjects.vue')
          },
          {
            path: '/projects/disapproved',
            name: 'Projects Disapproved',
            meta: { name: "projects"},
            component: () => import('@/views/projects/disapprovedProjects.vue')
          },
        ]
      },
      {
        path: 'events/',
        name: 'Events',
        meta: { name: "event"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/events/irc-score',
        children: [
          {
            path: '/events/irc-score',
            name: 'Team Listing',
            meta: { name: "event"},
            component: () => import('@/views/events/irc_score_listing.vue')
          },
          {
            path: '/events/create',
            name: 'Create Event',
            meta: { name: "event"},
            component: () => import('@/views/events/create_event.vue')
          },
          {
            path: '/events/edit/:event_code',
            name: 'Edit Event',
            meta: { name: "event"},
            component: () => import('@/views/events/edit_event.vue')
          },
          {
            path: '/events/league-score/:team_id',
            name: 'League Score',
            meta: { name: "event"},
            component: () => import('@/views/events/league_score.vue')
          },
          {
            path: '/events/zonal-score/:event_code/:row_id/:team_id/:zonal',
            name: 'Zonal Score',
            meta: { name: "event"},
            component: () => import('@/views/events/zonal_score.vue')
          },
          {
            path: '/events/stage1-score/:event_id/:row_id/:team_id',
            name: 'Stage1 Score',
            meta: { name: "event"},
            component: () => import('@/views/events/stage1_score.vue')
          },
          {
            path: '/events/tasks',
            name: 'Event tasks',
            meta: { name: "event"},
            component: () => import('@/views/events/tasks.vue')
          },
          {
            path: '/events/editTeam',
            name: 'Team Edit',
            meta: { name: "event"},
            component: () => import('@/views/events/editTeam.vue')
          },
          {
            path: '/events/judges',
            name: 'Judges Panel',
            meta: { name: "event"},
            component: () => import('@/views/events/judges.vue')
          },
          {
            path: '/events/stage2_scoringAmGl/:event_id',
            name: 'Stage2 Scoring',
            meta: { name: "event"},
            component: () => import('@/views/events/stage2ScoringAmGl.vue')
          },
          {
            path: '/events/stage2-score/:event_id/:row_id/:team_id',
            name: 'Stage2 Score',
            meta: { name: "event"},
            component: () => import('@/views/events/stage2_score.vue')
          },
          {
            path: '/events/zonal_scoring/:event_id/:zonal?',
            name: 'Zonal Scoring',
            meta: { name: "event"},
            component: () => import('@/views/events/zonalScoring.vue')
          },
          {
            path: '/events/stage1_scoring/:event_id',
            name: 'Stage1 Scoring',
            meta: { name: "event"},
            component: () => import('@/views/events/stage1Scoring.vue')
          },
          {
            path: '/events/stage2_scoring_admin/:event_id',
            name: 'Stage2 Scoring Admin',
            meta: { name: "event"},
            component: () => import('@/views/events/stage2Scoring_admin.vue')
          },
          {
            path: '/events/tasks/add',
            name: 'Add tasks',
            meta: { name: "event"},
            component: () => import('@/views/events/addTask.vue')
          },
          {
            path: '/events/tasks/edit/:task_id',
            name: 'Edit tasks',
            meta: { name: "event"},
            component: () => import('@/views/events/editTask.vue')
          },
          {
            path: '/events/tasks/list',
            name: 'Tasks List',
            meta: { name: "event"},
            component: () => import('@/views/events/taskListing.vue')
          },
          {
            path: '/events/listing',
            name: 'Event Listing',
            meta: { name: "event"},
            component: () => import('@/views/events/eventListing.vue')
          }
        ]
      },
      {
        path: 'partner/',
        name: 'Partner',
        meta: { name: "partner_admin"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/partner/listing',
        children: [
          {
              path: '/partner/listing',
              name: 'Partner Listing',
              meta: { name: "partner_admin"},
              component: () => import('@/views/partner/partner_listing.vue')
          }
        ]
      },
      {
        path: 'orders/',
        name: 'Orders',
        meta: { name: "order_form"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/orders/listing',
        children: [
          {
              path: '/orders/listing',
              name: 'Orders Listing',
              meta: { name: "order_form"},
              component: () => import('@/views/orders/order_listing.vue')
          },
          {
            path: '/orders/details/:order_id',
            name: 'Orders Details',
            meta: { name: "order_form"},
            component: () => import('@/views/orders/order_details.vue')
        }
        ]
      },
      {
        path: 'blogs/',
        name: 'Blogs',
        meta: { name: "Blogs"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/blogs/listing',
        children: [
          {
            path: '/blogs/listing',
            name: 'Blog Listing',
            meta: { name: "blogs"},
            component: () => import('@/views/blogs/blogListing.vue')
          },
          {
            path: '/blogs/create',
            name: 'Create Blog',
            meta: { name: "blogs"},
            component: () => import('@/views/blogs/addBlog.vue')
          },
          {
            path: '/blogs/edit/:blog_uri',
            name: 'Edit Blog',
            meta: { name: "blogs"},
            component: () => import('@/views/blogs/editBlog.vue')
          }
        ]
      },
      {
        path: 'notifications/',
        name: 'notifications',
        meta: { name: "notification_admin"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/create',
        children: [
          {
            path: '/notifications/create',
            name: 'Create Notification',
            meta: { name: "notification_admin"},
            component: () => import('@/views/notification_admin/create.vue')
          },
          {
            path: '/notifications/listing',
            name: 'Listing Notification',
            meta: { name: "notification_admin"},
            component: () => import('@/views/notification_admin/listing.vue')
          }
        ]
      },
      {
        path: 'purchase/',
        name: 'Inside Sales',
        meta: { name: "inside_sales"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/purchase/listing',
        children: [
          {
              path: '/purchase/listing',
              name: 'Inside Sales Listing',
              meta: { name: "inside_sales"},
              component: () => import('@/views/purchase/sales_listing.vue')
          },
          {
            path: '/purchase/create/url',
            name: 'Create URL',
            meta: { name: "inside_sales"},
            component: () => import('@/views/purchase/create_url.vue')
        }
        ]
      },
      {
        path: 'license/',
        name: 'Licenses',
        meta: { name: "inside_sales"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/license/listing',
        children: [
          {
              path: '/license/listing',
              name: 'License Listing',
              meta: { name: "license_admin"},
              component: () => import('@/views/license/license_listing.vue')
          },
          {
            path: '/license/items/:license_code',
            name: 'License Items',
            meta: { name: "license_admin"},
            component: () => import('@/views/license/license_items.vue')
          },
          {
            path: '/license/creator/details/:userId',
            name: 'Creator License Details',
            meta: { name: "license_admin"},
            component: () => import('@/views/license/creator_license_details.vue')
        },
        ]
      },
      {
        path: 'sku/',
        name: 'Avishkaar SKU',
        meta: { name: "sku"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/sku/listing',
        children: [
          {
              path: '/sku/listing',
              name: 'Sku Listing',
              meta: { name: "sku"},
              component: () => import('@/views/sku/product_listing.vue')
          },
          {
            path: '/sku/add/product',
            name: 'Add Sku',
            meta: { name: "sku"},
            component: () => import('@/views/sku/add_product.vue')
          },
          {
            path: '/sku/edit/:product_code',
            name: 'Edit Sku',
            meta: { name: "sku"},
            component: () => import('@/views/sku/edit_product.vue')
          }
        ]
      },
      {
        path: 'release/:feature',
        name: 'Avishkaar release',
        meta: { name: "pipeline"},
        component: () => import('@/views/release/shop_release.vue')
      },
      {
        path: 'course/',
        name: 'Course',
        meta: { name: "course"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/course/listing',
        children: [
          {
              path: '/course/listing',
              name: 'Course Listing',
              meta: { name: "course"},
              component: () => import('@/views/course/course_listing.vue')
          },
          {
            path: '/course/edit/:course_code',
            name: 'Edit Course',
            meta: { name: "course"},
            component: () => import('@/views/course/edit_course.vue')
          },
          {
            path: '/course/add',
            name: 'Add Course',
            meta: { name: "course"},
            component: () => import('@/views/course/add_course.vue')
          },
        ]
      },
      {
        path: 'inventory/',
        name: 'Inventory',
        meta: { name: "inventory"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/inventory/order/listing',
        children: [
          {
              path: '/inventory/order/listing',
              name: 'Order Listing',
              meta: { name: "inventory"},
              component: () => import('@/views/inventory/order_listing.vue')
          },
          {
            path: '/iventory/order/details/:order_id',
            name: 'Order Details',
            meta: { name: "inventory"},
            component: () => import('@/views/inventory/order_details.vue')
          },
          {
            path: '/inventory/place/order/:type?/:code?/:quantity?',
            name: 'Place Order',
            meta: { name: "inventory"},
            component: () => import('@/views/inventory/place_order.vue')
          },
          {
            path: '/inventory/stock',
            name: 'Inventory Stock',
            meta: { name: "inventory"},
            component: () => import('@/views/inventory/stock.vue')
          },
          {
            path: '/inventory/add/stock',
            name: 'Inventory Add Stock',
            meta: { name: "inventory"},
            component: () => import('@/views/inventory/add_stock.vue')
          },
          {
            path: '/inventory/cost',
            name: 'Inventory Cost',
            meta: { name: "inventory"},
            component: () => import('@/views/inventory/cost.vue')
          },
          {
            path: '/inventory/internal/component',
            name: 'Component for Internal Purpose',
            meta: { name: "inventory"},
            component: () => import('@/views/inventory/internal_component.vue')
          },
          {
            path: '/inventory/rejected/orders',
            name: 'Inventory Orders With Rejected Material',
            meta: { name: "inventory"},
            component: () => import('@/views/inventory/rejected_orders.vue')
          },
        ]
      },
      {
        path: 'ams-play/',
        name: 'AMS Play',
        meta: { name: "ams_play"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/ams-play/types',
        children: [
          {
              path: '/ams-play/types',
              name: 'AMS Play Types',
              meta: { name: "ams_play"},
              component: () => import('@/views/ams_play/ams_play_types.vue')
          },
          {
            path: '/ams-play/details/:type/:category_id',
            name: 'AMS Play Category Details',
            meta: { name: "ams_play"},
            component: () => import('@/views/ams_play/ams_play_category_details.vue')
        }
        ]
      },
      {
        path: 'community/',
        name: 'Community',
        meta: { name: "community_dashboard"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/community/dashboard',
        children: [
          {
              path: '/community/dashboard',
              name: 'Community Dashboard',
              meta: { name: "community_dashboard"},
              component: () => import('@/views/community/dashboard.vue')
          },
          {
            path: '/community/user/details/:user_id?',
            name: 'Community User Details',
            meta: { name: "community_dashboard"},
            component: () => import('@/views/community/user_details.vue')
        }
        ]
      },
      {
        path: 'service/request/',
        name: 'Service Request',
        meta: { name: "service_request"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: 'service/request/listing',
        children: [
          {
              path: '/service/request/listing',
              name: 'Service Request Listing',
              meta: { name: "service_request"},
              component: () => import('@/views/service_request/request_listing.vue')
          },
          {
            path: '/service/request/:request_id',
            name: 'Service Request Detail',
            meta: { name: "service_request"},
            component: () => import('@/views/service_request/request_details.vue')
        }
        ]
      },
      {
        path: 'popup/',
        name: 'Avishkaar Pop-ups',
        meta: { name: "avishkaar_popup"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/popup/listing',
        children: [
          {
              path: '/popup/listing',
              name: 'Avishkaar Pop-ups Listing',
              meta: { name: "avishkaar_popup"},
              component: () => import('@/views/popup/listing.vue')
          },
          {
            path: '/popup/create',
            name: 'Create Pop-ups',
            meta: { name: "avishkaar_popup"},
            component: () => import('@/views/popup/create_popup.vue')
          },
          {
            path: '/popup/detail/:popup_id',
            name: 'Edit Pop-ups',
            meta: { name: "avishkaar_popup"},
            component: () => import('@/views/popup/create_popup.vue')
          }
        ]
      },
      {
        path: 'discount/',
        name: 'Discount Coupon',
        meta: { name: "discount_coupon"},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/discount/coupon/listing',
        children: [
          {
              path: '/discount/coupon/listing',
              name: 'Coupon Listing',
              meta: { name: "discount_coupon"},
              component: () => import('@/views/discount/coupon_listing.vue')
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to,from,next) => {
  if(to.meta.name !== "home"){
    store.dispatch("checkUserAdmin",to.meta.name).then(result => {
    next()
  }).catch(error => {
    next({name: "Dashboard"})
  })
  }else{
    next()
  } 
})

export default router
