import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
  state: {
    appProductsDetails: [],
    liveProducts: [],
    allProducts: [],
    allSKU: [],
    allAccessories: [],
    availableProduct: [],
    rawMaterial: [],
    printableFileExist: false,
    rawMaterialTypes: [],
    rawMaterialComponents: [],
    semiFinishedAssembly: [],
    productRawMaterialBom: [],
    rawMaterialTrackData: [],
    availableFinishedStock: [],
    availableSemiFinishedStock: [],
    wipBom: [],
    finishedBom: [],
    inventoryOrderDetails: false,
    purchaseOrderDetails: false,
    materialVendor: [],
    materialComponentVendor: [],
    vendorInvoices: []
  },
  getters: {
    getAppProductsDetails: (state) => state.appProductsDetails,
    getLiveProducts: (state) => state.liveProducts,
    getallProducts: (state) => state.allProducts,
    getallSKU: (state) => state.allSKU,
    getallAccessories: (state) => state.allAccessories,
    getAvailableProduct: (state) => state.availableProduct,
    getRawMaterials: (state) => state.rawMaterial,
    getPrintableFileExist: (state) => state.printableFileExist,
    getPrintableFileExist: (state) => state.printableFileExist,
    getRawMaterialType: (state) => state.rawMaterialTypes,
    getRawMaterialComponent: (state) => state.rawMaterialComponents,
    getSemiFinishedAssembly: (state) => state.semiFinishedAssembly,
    getProductRawMaterialBom: (state) => state.productRawMaterialBom,
    getRawMaterialComponentTrack: (state) => state.rawMaterialTrackData,
    getAvailableFinishedStock: (state) => state.availableFinishedStock,
    getAvailableSemiFinishedStock: (state) => state.availableSemiFinishedStock,
    getwipBom: (state) => state.wipBom,
    getFinishedBom: (state) => state.finishedBom,
    getInventoryOrderDetails: (state) => state.inventoryOrderDetails,
    getPurchaseOrderDetails: (state) => state.purchaseOrderDetails,
    getRawMaterialVendor: (state) => state.materialVendor,
    getRawMaterialComponentVendor: (state) => state.materialComponentVendor,
    getVendorInvoices: (state) => state.vendorInvoices,
  },
  actions: {
    fetchLiveProducts({ commit}) {
      axios.get(`${BASE_URL}/shop/products/listing`).then((response) => {
        commit("setLiveProducts",response.data.data)
      });
    },
    fetchALLSKU({ commit}) {
      axios.get(`${BASE_URL}/products/all/sku`).then((response) => {
        commit("setALLSKU",response.data.data)
      });
    },
    fetchAppProductDetails({ commit}) {
        axios.get(`${BASE_URL}/products/all/app`).then((response) => {
          commit("setAppProductsDetails",response.data.result)
        });
    },
    fetchRawMaterials({ commit}) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/raw/materials`).then((response) => {
          commit("setRawMaterials",response.data.data)
          resolve(response.data.data)
        });
      })
    },
    fetchRawMaterialComponentDetails({ }, component_id) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/raw/material/component/details/${component_id}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchProductAppData({ commit}, productCode) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/app/${productCode}`).then((response) => {
          resolve(response.data.result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addAppProductDetails({ dispatch }, productPayload){
      return new Promise((resolve,reject) => {
          axios.post(`${BASE_URL}/products/app/data`,productPayload).then(response => {
              dispatch('fetchAppProductDetails')
              resolve("success")
          }).catch(error => {
              reject(error)
          })
      })
    },
    fetchAllProducts({ commit}) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/category/product/all`).then((response) => {
          var productData = response.data.data.filter(product => product.product_delete != 1)
          commit("setALLProducts",productData)
          resolve("success")
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchAllAccessories({ commit}) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/category/accessory/all`).then((response) => {
          var productData = response.data.data.filter(product => product.product_delete != 1)
          commit("setALLAccessories",productData)
          resolve("success")
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchProductUri({ commit }, productUri){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/uri/${productUri}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateProductData({ }, productPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/product_code/${productPayload.product_code}`, productPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateSalePrice({ }, productPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/sale/price`, productPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateMaxDiscount({ }, productPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/max/discount`, productPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateStockQuantity({ }, productPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/stock`, productPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateProductUri({ }, productPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/uri`, productPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateProductLive({ }, productPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/live`, productPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchAvailableProduct({ commit }){
      axios.get(`${BASE_URL}/products/available`).then((response) => {
        commit("setAvailableProduct",response.data.finalProducts)
      });
    },
    fetchAvailableAccessories({ commit }){
      axios.get(`${BASE_URL}/products/available/accessory`).then((response) => {
        commit("setAvailableProduct",response.data.finalProducts)
      });
    },
    fetchEpochPrintableJson({ commit }){
      axios.get(`${BASE_URL}/products/epoch/print/json`).then((response) => {
        commit("setPrintableEpoch",response.data.data)
      }).catch(error => {
        commit("setPrintableEpoch",false)
      })
    },
    addProductEpoch({dispatch }, productPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/epoch`, productPayload).then((response) => {
          dispatch("fetchEpochPrintableJson")
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addPurchaseOrderEpoch({ }, productPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/order/epoch`, productPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteEpochPrintableJson({dispatch }){
      axios.put(`${BASE_URL}/products/remove/print/json`).then((response) => {
        dispatch("fetchEpochPrintableJson")
      })
    },
    checkIncompleteEpoch({ }, epochUrl){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/check/incomplete/finished/stock`,{ "epoch_url" : epochUrl }).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    checkIncompleteSemiFinishedEpoch({ }, epochKey){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/check/incomplete/semi/finished/stock`,{ "epoch_key" : epochKey }).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    checkComponentEpoch({}, epochPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/check/component/epoch`,epochPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    makeFinishedStock({}, stockPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/make/finished/stock`,stockPayload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    makeSemiFinishedStock({}, stockPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/make/semi/finished/stock`,stockPayload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetctRawMaterialTypes({ commit}) {
      axios.get(`${BASE_URL}/products/raw/material/types`).then((response) => {
        const formated_raw_material_types = response.data.data.map( type => {
          type.text = type.name
          return type
        })
        commit("setRawMaterialTypes",formated_raw_material_types)
      });
    },
    addMaterialType({dispatch}, rawTypePayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/raw/material/type`,rawTypePayload).then((response) => {
          dispatch("fetctRawMaterialTypes")
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateMaterialType({dispatch}, rawTypePayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/raw/material/type`,rawTypePayload).then((response) => {
          dispatch("fetctRawMaterialTypes")
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addMaterialComponent({dispatch}, rawComponentPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/raw/material/component`,rawComponentPayload).then((response) => {
          dispatch("fetchRawMaterialComponents",rawComponentPayload.raw_material_type_id)
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateComponentQuantity({dispatch}, updateComponentPayload){
      return new Promise((resolve,reject) => {
        if(updateComponentPayload.update_type == "add"){
          var url = `${BASE_URL}/products/raw/material/component/quantity`
        }else if(updateComponentPayload.update_type == "increase"){
          var url = `${BASE_URL}/products/increase/raw/material/component/quantity`
        }else{
          var url = `${BASE_URL}/products/reduce/raw/material/component/quantity`
        }
        axios.put(url,updateComponentPayload).then((response) => {
          dispatch("fetchRawMaterialComponents",updateComponentPayload.raw_material_type_id)
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchRawMaterialComponents({commit}, raw_material_type_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/raw/material/components/${raw_material_type_id}`).then((response) => {
          if(response.data.data){
            var formated_raw_material_components = response.data.data.map( component => {
              component.text = component.component_name
              return component
            })
          }else{
            var formated_raw_material_components = null
          }
          commit("setRawMaterialComponents",formated_raw_material_components)
          resolve(formated_raw_material_components)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchSemiFinishedAssembly({commit}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/sku/category/semi_finished_assembly`).then((response) => {
          commit("setSemiFinishedAssembly",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchWip2({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/sku/category/wip_2`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchSemiFinished({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/sku/category/semi_finished_goods`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchFinished({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/sku/category/finished_good`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchProductRawMaterialBom({commit}, product_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/sku/bom/${product_code}`).then((response) => {
          commit("setProductRawMaterialBom",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchWip2Bom({commit}, product_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/wip2/bom/${product_code}`).then((response) => {
          commit("setWipBom",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchSemiFinishedBom({commit}, product_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/semiFinished/bom/${product_code}`).then((response) => {
          commit("setWipBom",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchFinishedBom({commit}, product_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/finished/bom/${product_code}`).then((response) => {
          commit("setFinishedBom",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    setWipBomEmpty({commit}){
      commit("setWipBom",false)
    },
    addProductRawMaterialBom({dispatch}, rawMaterialBomPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/raw/material/bom`,rawMaterialBomPayload).then((response) => {
          dispatch("fetchProductRawMaterialBom",rawMaterialBomPayload.product_code)
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addWip1intoWip2({dispatch}, wip2Payload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/add/wip1/into/wip2`,wip2Payload).then((response) => {
          dispatch("fetchWip2Bom",wip2Payload.wip2_code)
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addWip2intoSemiFinished({dispatch}, semiFinishedPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/add/wip2/into/semiFinished`,semiFinishedPayload).then((response) => {
          dispatch("fetchSemiFinishedBom",semiFinishedPayload.semi_finished_code)
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addSemiFinishedintoFinished({dispatch}, finishedPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/add/semiFinished/into/Finished`,finishedPayload).then((response) => {
          dispatch("fetchFinishedBom",finishedPayload.finished_code)
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchRawMaterialTrack({commit}, component_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/raw/material/track/${component_id}`).then((response) => {
          commit("setRawMaterialTrack",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    uploadImage({}, file){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/upload/inventory`,{ 'name' : file.name }).then(async (response) => {
          console.log(response.data)
          await axios.put(response.data.url, file, { headers: {'Content-Type': file.type} })
          resolve(response.data.url.split("?Content-Type=")[0])
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    getOrders({}, orderPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/order/listing`,orderPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getOrderDetails({ commit }, order_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/order/details/${order_id}`).then((response) => {
          commit("setPurchaseOrderDetails",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    uploadOrder({}, uploadPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/upload/order`,uploadPayload).then(async (response) => {
          console.log(response.data)
          await axios.put(response.data.url, uploadPayload.file, { headers: {'Content-Type': uploadPayload.file.type} })
          resolve(response.data.fileName)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    addOrderInvoice({}, invoicePayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/order/invoice`,invoicePayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    UpdateOrderInvoiceAmount({}, invoicePayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/order/invoice`,invoicePayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    UpdateOrderStatus({}, statusPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/order/status`,statusPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getAllSalesChannel({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/purchase/salesChannel`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getAllSalesOwner({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/purchase/salesOwner`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    createInsideSaleUrl({}, urlPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/inside/url`,urlPayload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    sendInsideSaleMessage({}, token){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/externals/message/sales/inside/${token}/new`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    sendInsideSaleApprovalMessage({}, token){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/externals/sales/inside/approval/${token}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getInsideSalesListing({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/inside/sales/listing`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getALlCountries({}){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/country`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getStateByCountryId({}, country_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/state/${country_id}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getCityByStateId({}, state_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/city/${state_id}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addInsideSalesAddress({}, urlPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/inside/sale/address`,urlPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchProductRegistrations({ }) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/registration/listing`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchProductRegistrationEpochDetails({ }, productEpoch) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/registration/epoch/${productEpoch}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchAvailableFinishedStock({ commit }) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/finished/stock/listing`).then((response) => {
          commit("setAvailableFinishedStock",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchAvailableSemiFinishedStock({ commit }) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/semi/finished/stock/listing`).then((response) => {
          commit("setAvailableSemiFinishedStock",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchAvailableWip2Stock({ }) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/wip2/stock/listing`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchFinishedStockByCode({ commit }, product_code) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/finished/stockByCode/${product_code}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchSemiFinishedStockByCode({ commit }, product_code) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/semi/finished/stockByCode/${product_code}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchWip2StockByCode({ commit }, product_code) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/wip2/stockByCode/${product_code}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchSkuAllCategory({ }) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/sku/all/category`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchProductByCode({ }, product_code) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/sku/${product_code}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchAllSku({ }) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/all/sku`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchSkuByCode({ }, sku_code) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/sku/${sku_code}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addSkuDetails({ }, skuPayload) {
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/add/sku/details`, skuPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateSkuDetails({ }, skuPayload) {
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/sku/details`, skuPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchBulkSkuListing({ }) {
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/bulk/sku/listing`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    placeInventoryOrder({ }, inventoryPayload) {
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/inventory/order`, inventoryPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getInventoryOrders({}, orderPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/inventory/order/listing`,orderPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchInventoryOrderDetails({ commit }, inventory_order_id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/inventory/order/${inventory_order_id}`).then((response) => {
          commit("setInventoryOrderDetails",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    allocateInventoryOrderMaterial({}, materialPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/inventory/order/material`,materialPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    allocatePurchaseOrderMaterial({}, materialPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/purchase/order/material`,materialPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    allocateInventoryOrderAllMaterial({}, materialPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/inventory/material/allocation`,materialPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    allocateInventoryOrderExtraMaterial({}, materialPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/inventory/extra/raw/material`,materialPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateInventoryOrderStatus({}, statusPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/inventory/order/status`,statusPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateInventoryOrderStock({}, stockPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/inventory/stock`,stockPayload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchInventoryStock({ }){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/inventory/stock`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchProjectsByKit({ }, product_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/projects/getByKit/${product_code}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetctRawMaterialVendor({ commit}) {
      axios.get(`${BASE_URL}/products/raw/material/vendor`).then((response) => {
        commit("setRawMaterialVendor",response.data.data)
      });
    },
    addMaterialVendor({dispatch}, vendorPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/raw/material/vendor`,vendorPayload).then((response) => {
          dispatch("fetctRawMaterialVendor")
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    updateMaterialVendor({dispatch}, vendorPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/update/raw/material/vendor`,vendorPayload).then((response) => {
          dispatch("fetctRawMaterialVendor")
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    getRequiredRawMaterial({}, producyPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/raw/material/planning`,producyPayload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addInventoryStock({}, stockPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/add/stock`,stockPayload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    reduceInventoryStock({}, stockPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/reduce/wip1/stock`,stockPayload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    removeRawMaterialFromBom({}, materialPayload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/remove/bom/component`,materialPayload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    checkOrderPlace({}, orderPayload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/check/order/place`,orderPayload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    makeWip2({}, wip2Payload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/make/wip2/epoch`,wip2Payload).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchMinimumStock({ }){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/minimum/raw/material/stock`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchCriticalStock({ }, payload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/critical/raw/material/stock`,payload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchWeeklyReport({ }, payload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/material/week/report`,payload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchStockCost({ }, type){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/inventory/cost/${type}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchStockTotalCost({ }){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products//inventory/total/cost`).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    reprintQr({ }, payload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/reprint/epoch`,payload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    removeWip2Stock({ }, payload){
      return new Promise((resolve,reject) => {
        axios.put(`${BASE_URL}/products/remove/wip2/stock`,payload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchRejectedOrders({ }){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/inventory/rejected/orders`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchVendorInvoices({ commit }){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/vendor/invoice/listing`).then((response) => {
          commit("setVendorInvoices",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchVendorInvoiceById({ },id){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/vendor/invoice/id/${id}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    addVendorInvoice({ commit }, payload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/vendor/invoice/details`,payload).then((response) => {
          commit("setVendorInvoices",response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchWip2Planning({ }, payload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/wip2/bom/planning`,payload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchKitFullBom({ }, product_code){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/products/fetch/complete/bom/${product_code}`).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    createPurchaseOrderPickup({ }, order_payload){
      return new Promise((resolve,reject) => {
        axios.post(`${BASE_URL}/products/purchase/order/pickup`,order_payload).then((response) => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
  },
  mutations: {
    setAppProductsDetails: (state, productData) => (state.appProductsDetails = productData),
    setLiveProducts: (state, productData) => (state.liveProducts = productData),
    setALLProducts: (state, productData) => (state.allProducts = productData),
    setALLSKU: (state, skuData) => (state.allSKU = skuData),
    setALLAccessories: (state, accessoryData) => (state.allAccessories = accessoryData),
    setAvailableProduct: (state, productData) => (state.availableProduct = productData),
    setRawMaterials: (state, rawMaterial) => (state.rawMaterial = rawMaterial),
    setPrintableEpoch: (state, printable) => (state.printableFileExist = printable),
    setRawMaterialTypes: (state, materialTypeData) => (state.rawMaterialTypes = materialTypeData),
    setRawMaterialComponents: (state, materialComponentData) => (state.rawMaterialComponents = materialComponentData),
    setSemiFinishedAssembly: (state, semiFinishedAssembly) => (state.semiFinishedAssembly = semiFinishedAssembly),
    setProductRawMaterialBom: (state, productRawMaterialBom) => (state.productRawMaterialBom = productRawMaterialBom),
    setRawMaterialTrack: (state, rawMaterialTrackData) => (state.rawMaterialTrackData = rawMaterialTrackData),
    setAvailableFinishedStock: (state, finishedStock) => (state.availableFinishedStock = finishedStock),
    setAvailableSemiFinishedStock: (state, semiFinishedStock) => (state.availableSemiFinishedStock = semiFinishedStock),
    setWipBom: (state, wipBom) => (state.wipBom = wipBom),
    setFinishedBom: (state, finishedBom) => (state.finishedBom = finishedBom),
    setInventoryOrderDetails: (state, order_details) => (state.inventoryOrderDetails = order_details),
    setPurchaseOrderDetails: (state, order_details) => (state.purchaseOrderDetails = order_details),
    setRawMaterialVendor: (state, materialVendor) => (state.materialVendor = materialVendor),
    setRawMaterialComponentVendor: (state, materialComponentVendor) => (state.materialComponentVendor = materialComponentVendor),
    setVendorInvoices: (state, vendorInvoices) => (state.vendorInvoices = vendorInvoices)
  },
};
