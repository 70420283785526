import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
    state: {
        requestListing: [],
        requestDetail: {}
    },
    getters: {
        getRequestListing: (state) => state.requestListing,
        getRequestDetail: (state) => state.requestDetail,
    },
    actions: {
        getServiceRequestListing({commit},role){
            return new Promise((resolve,reject) => {
              axios.get(`${BASE_URL}/servicerequest/listing/${role}`).then((response) => {
                commit("setRequestListing",response.data.data)
                resolve(response.data.data)
              }).catch(error => {
                reject(error.response.data)
              })
            })
        },
        getServiceRequestDetailById({commit}, request_id){
            return new Promise((resolve,reject) => {
              axios.get(`${BASE_URL}/servicerequest/details/${request_id}`).then((response) => {
                commit("setRequestDetail",response.data.data)
                resolve(response.data.data)
              }).catch(error => {
                reject(error.response.data)
              })
            })
        },
        addRequestDetails({},payload){
            return new Promise((resolve,reject) => {
              axios.put(`${BASE_URL}/servicerequest/add/details`,payload).then((response) => {
                resolve(response.data.data)
              }).catch(error => {
                reject(error.response.data)
              })
            })
        },
        createRequestReversePickup({},payload){
            return new Promise((resolve,reject) => {
              axios.put(`${BASE_URL}/servicerequest/pickup`,payload).then((response) => {
                resolve(response.data.data)
              }).catch(error => {
                reject(error.response.data)
              })
            })
        },
        updateRequestStatus({},payload){
            return new Promise((resolve,reject) => {
              axios.put(`${BASE_URL}/servicerequest/update/status`,payload).then((response) => {
                resolve(response.data.data)
              }).catch(error => {
                reject(error.response.data)
              })
            })
        },
        updateRequestWarranty({ dispatch },payload){
          return new Promise((resolve,reject) => {
            axios.put(`${BASE_URL}/servicerequest/warranty`,payload).then((response) => {
              dispatch("getServiceRequestDetailById", payload.request_id)
              resolve(response.data.data)
            }).catch(error => {
              reject(error.response.data)
            })
          })
        },
        updateRequestTracking({ dispatch },payload){
          return new Promise((resolve,reject) => {
            axios.put(`${BASE_URL}/servicerequest/tracking`,payload).then((response) => {
              dispatch("getServiceRequestDetailById", payload.request_id)
              resolve(response.data.data)
            }).catch(error => {
              reject(error.response.data)
            })
          })
        },
    },
    mutations: {
        setRequestListing: (state, requestListing) => (state.requestListing = requestListing),    
        setRequestDetail: (state, requestDetail) => (state.requestDetail = requestDetail),    
    },
}