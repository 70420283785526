import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
  state: {
    getAllProjects: [],
    getAll: [],
    getApprovedStats: [],
    getDisapprovedStats: [],
  },
  getters: {
    getAllProjects: (state) => state.getAllProjects,
    getAll: (state) => state.getAll,
    getApprovedStats: (state) => state.getApprovedStats,
    getDisapprovedStats: (state) => state.getDisapprovedStats,
  },
  actions: {
    getAllProjects({ commit}, param) {
      return new Promise((resolve,reject)=>{
        axios.get(`${BASE_URL}/projects/adminPanel/${param}`).then((response) => {
          if(response.data.status=='success')
            commit("setAllProjects",response.data.data)
          else
            commit("setAllProjects",false)
          resolve(true)
          }).catch((err)=>{
            reject(err)
         });
      })
    },
    getProjectByUri({ commit}, param) {
      return new Promise((resolve,reject)=>{
        axios.get(`${BASE_URL}/projects/uri/${param}`).then((response) => {
          if(response.data.status=='success')
               resolve(response.data)
          }).catch((err)=>{
            reject(err)
         });
      })
    },
    getProjectLikesByUri({ commit}, param) {
      return new Promise((resolve,reject)=>{
        axios.get(`${BASE_URL}/projects/community/likes/${param}`).then((response) => {
          if(response.data.status=='success')
               resolve(response.data)
          }).catch((err)=>{
            reject(err)
         });
      })
    },
    getAll({ commit}) {
      return new Promise((resolve,reject)=>{
        axios.get(`${BASE_URL}/projects`).then((response) => {
          if(response.data)
            commit("setAll",response.data)
          else
            commit("setAll",false)
          resolve(true)
          }).catch((err)=>{
            reject(err)
         });
      })
    },
    getApprovedStats({ commit}) {
      return new Promise((resolve,reject)=>{
        axios.get(`${BASE_URL}/projects/adminApprovedStats`).then((response) => {
          if(response.data)
            commit("setAllApproved",response.data.data)
          else
            commit("setAllApproved",false)
          resolve(true)
          }).catch((err)=>{
            reject(err)
         });
      })
    },
    getDisapprovedStats({ commit}) {
      return new Promise((resolve,reject)=>{
        axios.get(`${BASE_URL}/projects/adminDisApprovedStats`).then((response) => {
          if(response.data)
            commit("setAllDisapproved",response.data.data)
          else
            commit("setAllDisapproved",false)
          resolve(true)
          }).catch((err)=>{
            reject(err)
         });
      })
    },
    approvalProcess({dispatch},payload){
      return new Promise((resolve,reject)=>{
        axios.post(`${BASE_URL}/projects/new-admin/approval`,payload).then(async (response)=>{
          if(response.data.status=='success'){
           await dispatch('getAllProjects',payload.refer)
          }
           resolve(true)
        }).catch((err)=>{
           reject(err)
        })
        })
    },
    
    upgradeProject({}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          let urlResponse = await axios.post(`${BASE_URL}/projects/upgradeProject`, payload);
          if (urlResponse.status="success") {
            resolve("updated successfully!");
          } else {
            throw('upgradeProject api failed!');
          }
        } catch(err) {
          reject(err);
        }
      });
    }

  },
  mutations: {
    setAllProjects: (state, projectData) => (state.getAllProjects = projectData),    
    setAll: (state, projectAll) => (state.getAll = projectAll),    
    setAllApproved: (state, projectAllApproved) => (state.getApprovedStats = projectAllApproved),    
    setAllDisapproved: (state, projectAllDisapproved) => (state.getDisapprovedStats = projectAllDisapproved),    
  },
};
